import { Urls } from '../backend/urls';
import { useSWRAndHandleErrors } from './swr/useSWRAndHandleErrors';
import { getUrlWithQueryParams, httpGetJson } from '../backend/http/http';
import { useContext } from 'react';
import { EntryAnalyticsContext } from '../../routes/home/entryAnalytics/EntryAnalyticsContext';
import { MobileTypeFilter } from '../../routes/home/components/MobileTypeDropdown';

export enum ServiceRegion {
  All = 0,
  UnitedKingdom = 1,
  Benelux,
  Ireland,
  UnitedStates,
  Netherlands,
  Belgium,
  Luxembourg,
  Germany,
  France,
  SouthAfrica,
  Canada,
  Sweden,
  Norway,
  Denmark,
}

export interface IAdminPortalAnalytics {
  companiesCount: number;
  sitesCount: number;
  mobileUsersCount: number;
  iosUsersCount: number;
  androidUsersCount: number;
  paymentAdminsCount: number;
  siteAdminsCount: number;
  systemAdminsCount: number;
  panelsCount: number;
  monitorsCount: number;
  callCount: number;
  callAnsweredCount: number;
}

export interface ICallNotificationTimings {
  lowestCallNotificationMilliSeconds: number;
  highestCallNotificationMilliSeconds: number;
  averageNotificationMilliSeconds: number;
}

function getPrevMonthDate(dateObj: Date) {
  const tempDateObj = new Date(dateObj);

  if (tempDateObj.getMonth) {
    tempDateObj.setMonth(tempDateObj.getMonth() - 1);
  } else {
    tempDateObj.setFullYear(tempDateObj.getFullYear() - 1);
    tempDateObj.setMonth(12);
  }

  return tempDateObj;
}

interface ITotalCountOptions {
  prevMonth: boolean;
  ignoreSite: boolean;
  targetDate: Date;
}

function buildTotalCountOptions(nowDate: Date, options?: Partial<ITotalCountOptions>): ITotalCountOptions {
  return {
    prevMonth: false,
    ignoreSite: false,
    targetDate: nowDate,
    ...(options || {}),
  };
}

export function useTotalCountWithDelta(options?: Partial<ITotalCountOptions>) {
  const { startDate, endDate } = useContext(EntryAnalyticsContext);
  const totalCountStart = useTotalCount({ targetDate: startDate, ...(options || {}) });
  const totalCountEnd = useTotalCount({ targetDate: endDate, ...(options || {}) });

  const totalCountDelta: TotalCountType = totalCountStart && totalCountEnd
    ? {
      companiesCount: totalCountEnd.companiesCount - totalCountStart.companiesCount,
      sitesCount: totalCountEnd.sitesCount - totalCountStart.sitesCount,
      mobileUsersCount: totalCountEnd.mobileUsersCount - totalCountStart.mobileUsersCount,
      iosUsersCount: totalCountEnd.iosUsersCount - totalCountStart.iosUsersCount,
      androidUsersCount: totalCountEnd.androidUsersCount - totalCountStart.androidUsersCount,
      paymentAdminsCount: totalCountEnd.paymentAdminsCount - totalCountStart.paymentAdminsCount,
      siteAdminsCount: totalCountEnd.siteAdminsCount - totalCountStart.siteAdminsCount,
      systemAdminsCount: totalCountEnd.systemAdminsCount - totalCountStart.systemAdminsCount,
      panelsCount: totalCountEnd.panelsCount - totalCountStart.panelsCount,
      monitorsCount: totalCountEnd.monitorsCount - totalCountStart.monitorsCount,
      callCount: totalCountEnd.callCount - totalCountStart.callCount,
      callAnsweredCount: totalCountEnd.callAnsweredCount - totalCountStart.callAnsweredCount,
    }
    : null;

  const isDeltaRequired = startDate.getMonth() !== endDate.getMonth()
    || startDate.getFullYear() !== endDate.getFullYear();

  return {
    totalCount: totalCountEnd,
    totalCountDelta,
    isDeltaRequired,
  };
}

function useAnalyticsQueryParams(argOptions?: Partial<ITotalCountOptions>) {
  const { company, site } = useContext(EntryAnalyticsContext);
  const nowDate = new Date();
  const { targetDate, ignoreSite, prevMonth } = buildTotalCountOptions(nowDate, argOptions);
  const companyId = company?.id;
  const siteId = site?.id;

  const monthAndYearQueryParams = {
    year: targetDate.getFullYear(),
    month: prevMonth ? getPrevMonthDate(targetDate).getMonth() + 1 : targetDate.getMonth() + 1,
  };

  return {
    companyId,
    siteId: ignoreSite ? undefined : siteId,
    ...monthAndYearQueryParams,
  };
}

export function useCallNotificationTimings(argsOptions?: Partial<ITotalCountOptions>): ICallNotificationTimings | null {
  const { mobileTypeFilter, startDate, endDate, entryRegion: region } = useContext(EntryAnalyticsContext);

  const mobileTypeFilterMap: Record<MobileTypeFilter, string | undefined> = {
    [MobileTypeFilter.All]: undefined,
    [MobileTypeFilter.ApnCallKit]: 'ios',
    [MobileTypeFilter.Fcm]: 'android',
  };

  const timeParams = startDate.getMonth() === endDate.getMonth()
      && startDate.getFullYear() === endDate.getFullYear()
    ? {
      month: startDate.getMonth() + 1,
      year: startDate.getFullYear(),
    }
    : {
      startYear: startDate.getFullYear(),
      startMonth: startDate.getMonth() + 1,
      endYear: endDate.getFullYear(),
      endMonth: endDate.getMonth() + 1,
    };
  const baseParams = useAnalyticsQueryParams(argsOptions);
  const callNotificationTimingsUrl = getUrlWithQueryParams(Urls.CallNotificationTimings(), {
    ...baseParams,
    ...timeParams,
    region,
    mobileType: mobileTypeFilterMap[mobileTypeFilter || MobileTypeFilter.All],
  });

  const { data: callNotificationTimings } = useSWRAndHandleErrors<ICallNotificationTimings>(
    callNotificationTimingsUrl,
    httpGetJson,
  );

  return !callNotificationTimings ? null : callNotificationTimings;
}

export type TotalCountType = IAdminPortalAnalytics | null;
export function useTotalCount(
  argOptions?: Partial<ITotalCountOptions>,
): TotalCountType {
  const { entryRegion: region } = useContext(EntryAnalyticsContext);
  const queryParams = useAnalyticsQueryParams(argOptions);
  const adminPortalAnalyticsUrl = getUrlWithQueryParams(Urls.TotalCount(region), queryParams);

  const { data: adminPortalAnalytics } = useSWRAndHandleErrors<IAdminPortalAnalytics>(
    adminPortalAnalyticsUrl,
    httpGetJson,
  );

  return !adminPortalAnalytics ? null : adminPortalAnalytics;
}

function getGrowth(originalCount: number, countChangedBy: number) {
  if (originalCount === 0) {
    return countChangedBy > 0 ? 1 : 0;
  }
  return countChangedBy / originalCount;
}

export function useGrowthPercents(): IAdminPortalAnalytics | null {
  const { entryRegion } = useContext(EntryAnalyticsContext);
  const totalCount = useTotalCount();
  const { data: countChangedByInLastMonth } = useSWRAndHandleErrors<IAdminPortalAnalytics>(
    Urls.ChangesForLastMonth(entryRegion),
    httpGetJson,
  );
  if (!countChangedByInLastMonth || !totalCount) {
    return null;
  } else {
    return Object.entries(countChangedByInLastMonth).reduce((acc, [key, value]) => {
      const castedKey = key as keyof IAdminPortalAnalytics;
      const castedValue = value as number;
      const castedAcc = acc as IAdminPortalAnalytics;
      return {
        ...castedAcc,
        [castedKey]: getGrowth(totalCount[castedKey], castedValue),
      };
    }, {}) as IAdminPortalAnalytics;
  }
}
